import { COLOURS } from '@core-ui/styles';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { SxProps, Theme } from '@mui/material/styles';
import { generateEmptyArray } from '@/app/utils/app';
import React from 'react';
import { useIntl } from 'components/Intl';

const headerStyle: SxProps<Theme> = {
  minHeight: 38,
  backgroundColor: COLOURS.Coconut.Solid[700],
};

const rowStyle: SxProps<Theme> = {
  minHeight: 20,
  margin: '10px 0',
};

interface IProps {
  rows: number;
}

const TableSkeleton = (props: IProps) => {
  const { rows } = props;
  const intl = useIntl();

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      overflow="hidden"
      role="progressbar"
      aria-label={intl.formatMessage({ id: 'aria.label.loading' })}
    >
      <Skeleton variant="rounded" sx={headerStyle} height={38} />

      {generateEmptyArray(rows).map((_, index) => (
        <Skeleton key={index} sx={rowStyle} variant="rounded" height={20} />
      ))}
    </Grid>
  );
};

export default TableSkeleton;
