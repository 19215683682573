import { useAppSelector } from '@/hooks/useAppSelector';
import Loader from '@core-ui/loader';
import { HEIGHT_SIZE } from '@core-ui/styles';
import Grid from '@mui/material/Grid';
import React, { ReactNode } from 'react';
import selector from './selector';

interface IProps {
  header?: ReactNode;
  children: ReactNode;
}

const Layout = (props: IProps) => {
  const { header, children } = props;

  const { appReady } = useAppSelector(selector);

  if (!appReady) {
    return (
      <Grid container direction="column" justifyContent="center" alignItems="center" height="100vh">
        <Loader mode="circle" padding={5} />
      </Grid>
    );
  }

  return (
    <>
      {header}

      <Grid container direction="column" flex="1 1 auto" paddingTop={`${HEIGHT_SIZE.headerHeight}px`}>
        {children}
      </Grid>
    </>
  );
};

export default Layout;
