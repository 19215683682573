import TextField, { TextFieldProps } from '@core-ui/text_field';
import { onChangeWithEvent, onChangeWithoutEvent } from '@core-ui/text_field/types';
import { Nullable } from '@core-ui/types';
import React, { useEffect, useRef, useState } from 'react';
import formatValue from './formatter';

interface IProps extends Omit<TextFieldProps, 'onChange'> {
  onChange?: onChangeWithoutEvent;
  maxValue?: number;
  minValue?: number;
  value: string;
  onlyPositiveFloat?: boolean;
  onlyPositiveInteger?: boolean;
}

const NumberField = (props: IProps) => {
  const { onChange, value, maxValue, minValue, onlyPositiveFloat, onlyPositiveInteger, onBlur, ...rest } = props;
  const ref = useRef<Nullable<HTMLInputElement>>();

  const [newValue, setValue] = useState(`${value ?? ''}`);

  const handleChange: onChangeWithEvent = (e) => {
    const element = ref.current!;
    // @ts-ignore
    const char = e.nativeEvent.data as Nullable<string>;
    const input = e.target.value;

    if (onlyPositiveFloat && char && Number.isNaN(+char) && char !== '.') {
      if (element) {
        // user input non number ex: `123` -> `1b23`
        const position = element.selectionStart! - 1;
        element.setSelectionRange(position, position);
      }

      return;
    }

    if (onlyPositiveInteger && char && Number.isNaN(+char)) {
      if (element) {
        // user input non number ex: `123` -> `1b23`
        const position = element.selectionStart! - 1;
        element.setSelectionRange(position, position);
      }

      return;
    }

    if (char && Number.isNaN(+char) && char !== '.' && char !== '-') {
      if (element) {
        // user input non number ex: `123` -> `1b23`
        const position = element.selectionStart! - 1;
        element.setSelectionRange(position, position);
      }

      return;
    }

    if (input.length === 0) {
      setValue('');
      onChange?.('');

      return;
    }

    if (input === '.' || input === '-') {
      setValue(input);
      onChange?.(input);

      element.value = input;
      element.setSelectionRange(1, 1);

      return;
    }

    const [formattedValue, changedValue, cursorPosition] = formatValue(
      // @ts-ignore
      e.nativeEvent.inputType === 'insertFromPaste',
      element,
      input,
      newValue,
      minValue,
      maxValue
    );

    if (!formattedValue || !changedValue) {
      return;
    }

    setValue(formattedValue);
    onChange?.(changedValue);

    element.setSelectionRange(cursorPosition, cursorPosition);
  };

  useEffect(() => {
    const [formattedValue, changedValue] = formatValue(
      false,
      ref.current!,
      `${value ?? ''}`,
      newValue,
      minValue,
      maxValue
    );

    if (!formattedValue || !changedValue) {
      setValue('');
      onChange?.('');

      return;
    }

    setValue(formattedValue);
    onChange?.(changedValue);
  }, [value]);

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    // remove dot if needed ex: `100.` -> `100`
    if (newValue.endsWith('.')) {
      setValue(newValue.slice(0, -1));
    }

    onBlur?.(e);
  };

  return (
    <TextField {...rest} isEventable inputRef={ref} onChange={handleChange} value={newValue} onBlur={handleBlur} />
  );
};

export default NumberField;
