import Button, { ButtonProps } from '@core-ui/button';
import { Nullable } from '@core-ui/types';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { useIntl } from 'components/Intl';
import Menu from 'components/Menu';
import { IMenuOption } from '@/components/Menu/Menu';
import { DotsThreeVertical } from '@phosphor-icons/react';
import React, { useRef, useState } from 'react';
import { ButtonVariant } from '@core-ui/button/types';

interface IProps {
  options: IMenuOption[];
  loading?: boolean;
  disabled?: boolean;
  disablePortal?: boolean;
  buttonVariant?: ButtonVariant;
  isRounded?: boolean;
  padding?: number;
  styles?: SxProps<Theme>;
  buttonProps?: ButtonProps;
  zIndex?: number;
  dataTestId?: string;
}

const MoreButton = (props: IProps) => {
  const {
    options,
    loading,
    disabled,
    buttonProps,
    disablePortal,
    buttonVariant = 'outlined',
    isRounded,
    padding,
    styles = {},
    zIndex,
    dataTestId,
  } = props;

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<Nullable<HTMLDivElement>>(null);
  const intl = useIntl();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: TouchEvent | MouseEvent | React.MouseEvent<HTMLLIElement>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as Node)) {
      return;
    }

    setOpen(false);
  };

  const defaultStyles: SxProps<Theme> = {
    padding: padding ? `${padding}px !important` : undefined,
  };

  return (
    <>
      <div ref={anchorRef} style={{ display: 'inline-block', height: '100%' }}>
        <Button
          variant={buttonVariant}
          isRounded={isRounded}
          onClick={handleToggle}
          sx={{
            ...defaultStyles,
            ...styles,
          }}
          loading={loading}
          disabled={disabled}
          icon={<DotsThreeVertical weight="bold" />}
          aria-label={intl.formatMessage({ id: 'aria.label.open_more_options_menu' })}
          {...buttonProps}
        />
      </div>

      <Menu
        options={options}
        open={open}
        anchorRef={anchorRef}
        onClose={handleClose}
        disablePortal={disablePortal}
        zIndex={zIndex}
      />
    </>
  );
};

export default MoreButton;
