import Immutable from '@core-ui/immutable';
import reducer from '@core-ui/reducers_handlers';
import { Nullable } from '@core-ui/types';
import { BoatConfig, BoatWithConfigSchemaModel, EmployeeSchema } from '@/generated';
import { Action, handleActions } from 'redux-actions';
import * as actions from './actions';
import { getWindowBreakpoint } from './utils/app';

export interface AppState {
  appReady: boolean;
  loadedActions: Record<string, unknown>;
  windowBreakpoint: number;
  currentBoat: Nullable<BoatWithConfigSchemaModel>;
  settings: { dateFormat: string };
  user: Nullable<EmployeeSchema>;
  chatSocketConnected: boolean;
  userCostArticlesMappingLoading: boolean;
}

const defaultState = Immutable<AppState>({
  appReady: false,
  loadedActions: {},
  windowBreakpoint: getWindowBreakpoint(window.innerWidth),
  currentBoat: null,
  settings: {
    // https://date-fns.org/v2.28.0/docs/format
    dateFormat: 'd.LL.yyyy',
  },
  user: null,
  chatSocketConnected: false,
  userCostArticlesMappingLoading: false,
});

export default handleActions<typeof defaultState, any>(
  {
    [actions.setWindowBreakpoint.toString()]: (state, { payload }: Action<number>) => {
      return state.set('windowBreakpoint', payload);
    },
    [actions.setAppReady.toString()]: (state) => {
      return state.set('appReady', true);
    },
    [actions.setLoadedActions.toString()]: (state, { payload = {} }: Action<Record<string, any>>) => {
      if (!payload) {
        return state;
      }

      return state.merge({
        loadedActions: state.loadedActions.merge(payload),
      });
    },
    [actions.setCurrentBoat.toString()]: (state, { payload }: Action<BoatWithConfigSchemaModel | undefined>) =>
      state.set('currentBoat', payload || null),
    [actions.setCurrentBoatConfig.toString()]: (state, { payload }: Action<BoatConfig | undefined>) =>
      state.setIn(['currentBoat', 'boat_config'], payload || null),
    [actions.setUser.toString()]: (state, { payload }: Action<Nullable<EmployeeSchema>>) => state.set('user', payload),
    [actions.setChatSocket.toString()]: (state, { payload }: Action<boolean>) => {
      return state.set('chatSocketConnected', payload);
    },
    [actions.setUserCostArticlesMappingLoading.toString()]: (state, { payload }: Action<boolean>) => {
      return state.set('userCostArticlesMappingLoading', payload);
    },
  },
  reducer<typeof defaultState>('app', defaultState)
);
