import Boundary from '@core-ui/boundary';
import BoundaryLayout from '@core-ui/boundary/BoundaryLayout';
import { MySkyLoader } from '@core-ui/icons';
import { ScrollMemory } from '@core-ui/redux-router';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';
import { initChatAPI, initOceanAPI } from '@/api';
import Layout from '@/components/Layout/Layout';
import { configureStore, router } from '@/configureStore';
import history from '@/history';
import routes from '@/pages/routes';
import { client } from '@/sentry';
import { IconContext } from '@phosphor-icons/react';
import { IS_PROD_ENVIRONMENT } from 'app/consts/env';
import React from 'react';
import { IntlProvider } from 'components/Intl';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import TopMenuHeader from '../components/TopMenuHeader';
import { LOCALE_DATA } from './locales';
import theme from './styles/theme';
import { NotificationProvider, NotificationQueue } from 'components/Notification';

declare const VERSION: string;

const handleError = (error: Error) => {
  client.captureException(error);
};

// TODO: перенести сюда инициализацию истории когда будем выпиливать файл history.ts
// const history = createBrowserHistory();

const App = () => {
  // eslint-disable-next-line no-console
  console.log(VERSION);

  initOceanAPI();
  initChatAPI();

  const store = configureStore();
  const faviconMetaTag = document.querySelector('link[rel="shortcut icon"]') as HTMLLinkElement;

  // expose the store to the window to make it accessible by Playwright during testing
  if (process.env.NODE_ENV === 'development') {
    window.store = store;
  }

  if (faviconMetaTag && !IS_PROD_ENVIRONMENT) {
    faviconMetaTag.href = `${window.location.origin}/favicon-dev.ico`;
  }

  return (
    <Boundary layout={<BoundaryLayout />} onError={handleError}>
      <Router history={history}>
        <Provider store={store}>
          <StyledEngineProvider injectFirst>
            <StylesProvider>
              <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <IntlProvider locale="en" messages={LOCALE_DATA.en}>
                    <NotificationProvider>
                      <IconContext.Provider value={{ size: '100%' }}>
                        <ScrollMemory />
                        <CssBaseline />

                        <Layout header={<TopMenuHeader />}>
                          {router.render(routes, <MySkyLoader style={{ margin: 'auto' }} />)}
                        </Layout>

                        <NotificationQueue />
                      </IconContext.Provider>
                    </NotificationProvider>
                  </IntlProvider>
                </LocalizationProvider>
              </ThemeProvider>
            </StylesProvider>
          </StyledEngineProvider>
        </Provider>
      </Router>
    </Boundary>
  );
};

export default App;
