import { CurrencyIntlFunc } from '@/app/types/common';
import { createSelector, Selector } from 'reselect';
import State from './types/state';

export const getAppReady = (state: State) => state.app.appReady;
export const getLoadedActions = (state: State) => state.app.loadedActions;
export const getAppUserSelector = (state: State) => state.app.user;
export const getWindowBreakpoint = (state: State) => state.app.windowBreakpoint;
export const getDateFormat = (state: State) => state.app.settings.dateFormat;
export const getUserCostArticlesMappingLoading = (state: State) => state.app.userCostArticlesMappingLoading;

export const getCurrentBoatId = (state: State) => state.app.currentBoat?.id ?? null;
export const getCurrentBoatName = (state: State) => state.app.currentBoat?.name ?? null;
export const getCurrentBoatConfig = (state: State) => state.app.currentBoat?.boat_config ?? null;

// TODO: Это временный костыль для Nord. Уберём когда запилим exchange rates
export const shouldShowRub = (state: State) => state.app.currentBoat?.id === 2;

export const currencyIntlSelector = createSelector<[Selector<State, string>], CurrencyIntlFunc>(
  [() => 'en'],
  (locale: string) => (currency?: string, options?: Intl.NumberFormatOptions) => {
    if (currency) {
      return new Intl.NumberFormat(locale, {
        currency,
        style: 'currency',
        currencyDisplay: 'code',
        minimumFractionDigits: options?.minimumFractionDigits ?? 2,
        maximumFractionDigits: options?.maximumFractionDigits ?? 2,
      });
    }

    return new Intl.NumberFormat(locale, {
      minimumFractionDigits: options?.minimumFractionDigits ?? 2,
      maximumFractionDigits: options?.maximumFractionDigits ?? 2,
    });
  }
);

export const getBudgetApproveFlowEnabled = createSelector(
  [getCurrentBoatConfig],
  (config) => config?.is_budget_approve_flow
);

export const getLevelUserApprovalEnabled = createSelector(
  [getCurrentBoatConfig],
  (config) => config?.is_user_approval_enabled
);

export const getCostArticleMappingEnabled = createSelector(
  [getCurrentBoatConfig],
  (config) => config?.is_mapping_enabled
);
