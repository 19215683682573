import Immutable from '@core-ui/immutable';
import reducer from '@core-ui/reducers_handlers';
import {
  BoatWithConfigSchemaModel,
  ClientCostArticleInfo,
  CostArticleInfo,
  CostCenterSchema,
  CurrencySchemaModel,
  DepartmentSchemaModel,
  EmployeeSchema,
  RequestShortInfoSchema,
  VendorsInfo,
} from '@/generated';
import { handleActions } from 'redux-actions';
import * as actions from './actions';

export interface ICurrencyDict {
  id: number;
  name: string;
  code: string;
}

export interface IDictState {
  boats: BoatWithConfigSchemaModel[];
  currencies: CurrencySchemaModel[];
  currencyMap: Record<number, ICurrencyDict>;
  departments: DepartmentSchemaModel[];
  employees: EmployeeSchema[];
  costCenters: CostCenterSchema[];
  vendors: VendorsInfo[];
  costArticles: CostArticleInfo[];
  clientCostArticles: ClientCostArticleInfo[];
  requests: RequestShortInfoSchema[];

  // это нотификации о действиях пользователя которые хранятся на бэке, с NotificationProvider и его нотификациями они не связаны
  notifications: [];
}

const defaultState = Immutable<IDictState>({
  boats: [],
  currencies: [],
  currencyMap: {},
  departments: [],
  employees: [],
  costCenters: [],
  costArticles: [],
  clientCostArticles: [],
  vendors: [],
  requests: [],
  notifications: [],
});

export default handleActions<typeof defaultState, unknown>(
  {
    [actions.setBoatDict.toString()]: (state, { payload }) => {
      return state.set('boats', payload);
    },
    [actions.setClientCostArticleDict.toString()]: (state, { payload }) => {
      return state.set('clientCostArticles', payload);
    },
    [actions.setVendorDict.toString()]: (state, { payload }) => {
      return state.set('vendors', payload);
    },
    [actions.setRequestDict.toString()]: (state, { payload }) => {
      return state.set('requests', payload);
    },
    [actions.setUserNotificationDict.toString()]: (state, { payload }) => {
      return state.set('notifications', payload);
    },
  },

  reducer<typeof defaultState>(actions.reducerName, defaultState)
);
