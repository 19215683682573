import { useNotifications } from '@/components/Notification/NotificationProvider';
import { INotificationProps } from '@/components/Notification/types';
import { Alert, AlertTitle, Grow, Stack } from '@mui/material';
import Slide, { SlideProps } from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { FormattedMessage } from 'components/Intl';

const portalId = 'notification';
const autoHideDuration = 5000;
const TIMEOUT = 300;
const MAX_NOTIFICATIONS_IN_QUEUE = 3;

const TransitionDown = (props: SlideProps) => (
  <Slide {...props} direction="down">
    {props.children}
  </Slide>
);

interface IProps {
  notification: INotificationProps;
}

const Notification = (props: IProps) => {
  const { notification } = props;
  const { removeNotification } = useNotifications();
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);

    setTimeout(() => {
      removeNotification(notification.key);
    }, TIMEOUT);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleClose();
    }, notification.duration || autoHideDuration);

    return () => {
      clearTimeout(timeout);
    };
  }, [handleClose]);

  return (
    <Grow in={open} timeout={TIMEOUT} appear>
      <Alert
        variant="filled"
        severity={notification.variant}
        icon={false}
        onClose={handleClose}
      >
        {notification?.title && (
          <AlertTitle>
            <FormattedMessage id={notification.title} />
          </AlertTitle>
        )}

        {(Array.isArray(notification.message) ? notification.message : [notification.message]).map((message, index) => (
          <Typography key={index} variant="body1">
            {message}
          </Typography>
        ))}
      </Alert>
    </Grow>
  );
};

const Queue = () => {
  const { queue } = useNotifications();

  return (
    <>
      {queue.slice(0, MAX_NOTIFICATIONS_IN_QUEUE).map((notification) => (
        <Notification key={notification.key} notification={notification} />
      ))}
    </>
  );
};

const NotificationQueue = () => {
  let portal = document.getElementById(portalId);

  if (!portal) {
    const newPortal = document.createElement('div');
    newPortal.id = portalId;
    portal = newPortal;
    document.body.appendChild(newPortal);
  }

  return ReactDOM.createPortal(
    <Snackbar
      open
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      TransitionComponent={TransitionDown}
      disableWindowBlurListener
      ClickAwayListenerProps={{
        onClickAway: () => {
          // пустая функция, чтобы компонент не скрывался при клике за его пределы
        },
      }}
    >
      <Stack alignItems="end" direction="column" gap={2}>
        <Queue />
      </Stack>
    </Snackbar>,
    portal
  );
};

export default NotificationQueue;
