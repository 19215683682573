import { Nullable } from '@core-ui/types';
import isNil from 'lodash/isNil';

const numberFormat = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

const getMinMaxValue = (val: string, min?: number, max?: number) => {
  let result = val;

  if (!isNil(max)) {
    result = `${Math.min(max, +val)}`;
  }

  if (!isNil(min)) {
    result = `${Math.max(min, +result)}`;
  }

  return result;
};

export default function (
  paste: boolean,
  element: HTMLInputElement | undefined,
  inputValue: string,
  prevValue: string,
  minValue?: number,
  maxValue?: number
): [Nullable<string>, Nullable<string>, number] {
  const dotCount = inputValue.match(/\./g)?.length ?? -1;
  const commaCount = inputValue.match(/,/g)?.length ?? -1;

  let rawInputValue = inputValue;

  // if we have 33,33 value, this means that comma is dot actually
  if (paste && dotCount === -1 && commaCount === 1) {
    rawInputValue = rawInputValue.replace(',', '.');
  }

  rawInputValue =
    rawInputValue
      // remove all dots except last
      .replace(/\.(?=.*\.)/g, '')
      // remove all minus except first
      .replace(/(?!^)-?/g, '')
      // remove all except minus, digits, dot and digits after dot
      .replace(/[^-?\d.\d?]/g, '')
      // truncating digits after dot if needed and find expression
      .match(/-?\d+\.?\d{0,2}/)?.[0] || '';

  const postfixSeparator = rawInputValue.endsWith('.') ? '.' : '';
  const selectionShiftByDot = rawInputValue.endsWith('.') ? 1 : 0;
  const selectionShiftByMinus = rawInputValue.endsWith('-') ? 1 : 0;

  if (Number.isNaN(+rawInputValue) || rawInputValue.length === 0) {
    return [null, null, 0];
  }

  const rawValue = `${numberFormat.format(+rawInputValue)}${postfixSeparator}`;
  // we should append zero manually because NumberFormat formats number without zero at end
  const formattedValue = rawInputValue.endsWith('.0') ? `${rawValue}.0` : rawValue;

  const selectionStart = element?.selectionStart ?? 0;
  let cursorPosition;
  let value;
  if (rawInputValue.endsWith('.') || rawInputValue.endsWith('.0')) {
    cursorPosition = selectionStart + selectionShiftByDot + selectionShiftByMinus;
    value = formattedValue;
  } else {
    if (paste) {
      // user paste value
      const diff = formattedValue.match(/,/g)?.length ?? -1; // 1 -> move cursor right, -1 -> move cursor left
      cursorPosition = selectionStart + diff;
    } else {
      // use input char
      const separatorCount1 = prevValue.match(/,/g)?.length ?? 0;
      const separatorCount2 = formattedValue.match(/,/g)?.length ?? 0;
      const shift = separatorCount2 - separatorCount1; // 1 -> move cursor right, -1 -> move cursor left
      const cursorAtStart = selectionStart === 0 && shift === -1; // when user delete chars ex: `1,234` -> `,234`
      cursorPosition =
        formattedValue.length === 1 ? 1 : cursorAtStart ? 0 : selectionStart + shift + selectionShiftByDot;
    }

    const minMaxValue = getMinMaxValue(formattedValue.replace(/,/g, ''), minValue, maxValue);
    value = `${numberFormat.format(+minMaxValue)}`;
  }

  return [value, value.replace(/,/g, ''), cursorPosition];
}
