import { INotificationProps } from '@/components/Notification/types';
import React, { createContext, useContext, useEffect, useState } from 'react';

interface NotificationsContextProps {
  queue: INotificationProps[];
  addNotification: (notification: INotificationProps) => void;
  removeNotification: (key: INotificationProps['key']) => void;
}

export const MYOCEAN_NOTIFICATION_EVENT = 'myocean-notification';

const NotificationsContext = createContext<NotificationsContextProps>({
  queue: [],
  addNotification: (_notification) => {},
  removeNotification: (_key) => {},
});

export const useNotifications = () => useContext(NotificationsContext);

interface IProps {
  children?: React.ReactNode;
}

const NotificationProvider = (props: IProps) => {
  const { children } = props;
  const [notifications, setNotifications] = useState<INotificationProps[]>([]);

  const addNotification = (newNotification: INotificationProps) => {
    newNotification.key = newNotification.key ?? Date.now();

    setNotifications((notifications) => {
      // Prevent duplicated notifications
      if (notifications.find((notification) => notification.key === newNotification.key)) {
        return notifications;
      }

      return [newNotification, ...notifications];
    });
  };

  const removeNotification = (key: INotificationProps['key']) => {
    setNotifications((prev) => {
      return prev.filter((notification) => notification.key !== key);
    });
  };

  useEffect(() => {
    const listener = ({ detail }: WindowEventMap['myocean-notification']) => {
      addNotification(detail);
    };

    window.addEventListener(MYOCEAN_NOTIFICATION_EVENT, listener);

    return () => {
      window.removeEventListener(MYOCEAN_NOTIFICATION_EVENT, listener);
    };
  }, []);

  return (
    <NotificationsContext.Provider
      value={{
        queue: notifications,
        addNotification,
        removeNotification,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationProvider;
