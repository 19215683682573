import { getParams } from '@core-ui/redux-router';
import { buildURL } from '@core-ui/url';
import { GET, PATCH, setOceanAuthorization } from '@/api/oceanApi';
import { removeTokens } from '@/api/token-store';
import { getCostArticleMappingEnabled, getCurrentBoatId } from '@/app/selectors';
import { ISagaContext } from '@/app/types/common';
import { checkMobile } from '@/app/utils/app';
import { boatDictSelector } from '@/dictionary/selector';
import {
  BoatConfig,
  BoatUpdateSchema,
  BoatWithConfigSchemaModel,
  EmployeeSchema,
  HTTPValidationError,
} from '@/generated';
import { APPLICATION_PATHS } from '@/pages/routes';
import { ApplicationPaths } from '@/pages/types';
import { client } from '@/sentry';
import * as Sentry from '@sentry/browser';
import axios, { AxiosError } from 'axios';
import { isError } from 'lodash-es';
import { Action } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { all, call, getContext, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  getBoatList,
  getClientCostArticleDict,
  getCostArticleDict,
  getCostCenterDict,
  getCurrencyDict,
  getDepartmentDict,
  getEmployeeDict,
  setBoatDict,
} from 'dictionary/actions';
import * as actions from 'app/actions';
import { ROUTES } from './consts/routes';
import { MYOCEAN_NOTIFICATION_EVENT } from 'components/Notification/NotificationProvider';
import { INotificationProps } from 'components/Notification/types';

function* historyPush({ payload = '/' }: Action<string>) {
  const history: ISagaContext['history'] = yield getContext('history');

  history.push(payload);
  yield;
}

function* showNotification({ payload }: Action<INotificationProps>) {
  window.dispatchEvent(new CustomEvent(MYOCEAN_NOTIFICATION_EVENT, { detail: { ...payload } }));
  yield;
}

export function* responseError(error: any & actions.Error, options: actions.IErrorOptions = {}) {
  if (!error) {
    return;
  }

  const history: ISagaContext['history'] = yield getContext('history');

  // TODO: в разных ручках разные форматы ошибок, из-за этого приходится использовать такой if-else
  //  Нужно будет с бэком обсудить переход на единый формат ошибок который уже есть и описан в
  //  ноушене и затем переписать этот кусок кода
  let detail;

  if ('response' in error) {
    detail = (error as AxiosError<any>)?.response?.data?.detail;
  } else {
    detail = (error?.data as HTTPValidationError)?.detail;
  }

  const message = Array.isArray(detail)
    ? detail.map((d?: { msg: string }) => d?.msg).join('\n')
    : detail?.msg || detail || error?.message;

  const {
    defaultNotificationOptions = message
      ? {
          variant: 'error',
          message,
        }
      : {
          variant: 'error',
          title: 'notification.error.text.default',
        },
    notificationOptions401 = {
      variant: 'warning',
      title: 'notification.error.text.401',
    },
    notificationOptions403 = {
      variant: 'warning',
      title: 'notification.error.text.403',
    },
  } = options;

  switch (error.status || error.response?.status) {
    case 400: {
      yield put(actions.showNotification(defaultNotificationOptions));
      break;
    }

    case 401: {
      removeTokens();
      setOceanAuthorization(null);

      if (error.config.url?.startsWith(ROUTES.LOGIN)) {
        yield put(actions.showNotification(defaultNotificationOptions));
      } else {
        yield put(actions.setAppReady());
        history.push(`/${ROUTES.LOGIN}`);
        yield put(actions.showNotification(notificationOptions401));
      }

      break;
    }

    case 403: {
      yield put(actions.showNotification(notificationOptions403));
      break;
    }

    case 422: {
      yield put(actions.showNotification(defaultNotificationOptions));
      break;
    }

    default: {
      yield put(actions.showNotification(defaultNotificationOptions));
      break;
    }
  }

  Sentry.withScope((scope) => {
    scope.setLevel('warning');

    const axiosError = error as AxiosError<any> | undefined;
    if (axiosError?.isAxiosError) {
      if (!axios.isCancel(error)) {
        scope.setExtra('notification', 'http-client');
        Sentry.captureException(axiosError);
      }
    } else if (isError(error)) {
      scope.setExtra('notification', 'error');
      Sentry.captureException(error);
    } else {
      scope.setExtra('notification', 'unknown');
      Sentry.captureException(new Error(error.message));
    }
  });
}

function* getAppDictionaries() {
  yield all([
    put(getBoatList()),
    put(getCurrencyDict()),
    put(actions.getUser()),
    put(getDepartmentDict()),
    put(getEmployeeDict()),
    put(getCostCenterDict()),
  ]);
}

// TODO: очень мудрёная логика в этой саге, отрефачить/разбить бы её на отдельные части чтобы читаемей стало
function* initApp() {
  const history: ISagaContext['history'] = yield getContext('history');

  try {
    const isMobile: boolean = yield call(checkMobile);

    if (isMobile) {
      history.replace(`/${ROUTES.MOBILE}`);
    } else {
      yield put(actions.getAppDictionaries());
      yield all([take(setBoatDict), take(actions.setUser)]);

      const pathWithoutBoatId =
        history.location.pathname === '/' || history.location.pathname === `/${ROUTES.LOGIN}`
          ? `${ROUTES.BACKOFFICE}`
          : history.location.pathname.replace(/\/\d+\//, ''); // \/\d+\/ matches "/{boat_id}/"
      const query = history.location.search;
      const params = getParams<ApplicationPaths>(history.location.pathname, APPLICATION_PATHS);

      const isFAQ = params.route === ROUTES.FAQ;
      const boatIdString = params.boatId;

      if (!Number.isNaN(boatIdString)) {
        const boatId = Number(boatIdString);
        const boatDict: BoatWithConfigSchemaModel[] = yield select(boatDictSelector);

        const boat = boatDict.find((boat) => boat.id === boatId);

        if (boat) {
          yield put(actions.setCurrentBoat(boat));

          history.push(`/${boat.id}/${pathWithoutBoatId}${query}`);
        } else {
          const firstBoat = boatDict.at(0);

          if (firstBoat?.id) {
            yield put(actions.setCurrentBoat(firstBoat));

            if (!isFAQ) {
              history.push(`/${firstBoat.id}/${pathWithoutBoatId}${query}`);
            }
          } else if (!isFAQ) {
            history.push('/');
          }
        }
      } else {
        const boatDict: BoatWithConfigSchemaModel[] = yield select(boatDictSelector);
        const firstBoat = boatDict.at(0);

        if (firstBoat) {
          yield put(actions.setCurrentBoat(firstBoat));

          if (!isFAQ) {
            history.push(`/${firstBoat.id}/${pathWithoutBoatId}${query}`);
          }
        }
      }
    }

    yield put(actions.setAppReady());
  } catch (e) {
    removeTokens();
    setOceanAuthorization(null);
    history.push(`/${ROUTES.LOGIN}`);
  }
}

function* getUser() {
  try {
    const employee: EmployeeSchema = yield call(GET, '/employee');

    yield put(actions.setUser(employee));

    client.setUser({
      id: `${employee.id}`,
      email: employee.email,
    });
  } catch (e) {
    yield all([put(actions.setUser(null)), call(responseError, e)]);
  }
}

function* setResponseError({ payload }: Action<any & Error>) {
  yield call(responseError, payload);
}

function* toggleUserCostArticlesMapping() {
  try {
    yield put(actions.setUserCostArticlesMappingLoading(true));

    const boatId: string = yield select(getCurrentBoatId);
    const costArticleMappingEnabled: boolean = yield select(getCostArticleMappingEnabled);

    const url = buildURL(`/boats/${boatId}/config`);
    const body: BoatUpdateSchema = {
      is_mapping_enabled: !costArticleMappingEnabled,
    };

    const updatedConfig: BoatConfig = yield call(PATCH, url, body);

    if (!costArticleMappingEnabled) {
      yield put(getClientCostArticleDict());
    } else {
      yield put(getCostArticleDict());
    }

    yield all([
      put(actions.setCurrentBoatConfig(updatedConfig)),
      put(
        actions.showNotification({
          variant: 'success',
          title: 'notification.success.text.categories_changed',
        })
      ),
    ]);
  } catch (e) {
    yield call(responseError, e);
  } finally {
    yield put(actions.setUserCostArticlesMappingLoading(false));
  }
}

export default function* root(): SagaIterator {
  yield all([
    takeEvery(actions.initApp, initApp),
    takeEvery(actions.getUser, getUser),
    takeEvery(actions.setResponseError, setResponseError),
    takeEvery(actions.historyPush, historyPush),
    takeLatest(actions.getAppDictionaries, getAppDictionaries),
    takeLatest(actions.toggleUserCostArticlesMapping, toggleUserCostArticlesMapping),
    takeLatest(actions.showNotification, showNotification),
  ]);
}
