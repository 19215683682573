import { COLOURS } from '@core-ui/styles';
import { buildURL } from '@core-ui/url';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import { BACKEND_URL } from '@/app/consts/env';
import NoImagePlaceholder from '@/components/ImagesVerticalCarousel/NoImagePlaceholder';
import { ArrowsClockwise, DownloadSimple, Minus, Plus } from '@phosphor-icons/react';
import React, { useEffect, useState } from 'react';
import { INITIAL_ROTATE, INITIAL_ZOOM, MAX_ROTATE, MAX_ZOOM, MIN_ZOOM, STEP_ROTATE, STEP_ZOOM } from './consts';
import ImageItem from './ImageItem';
import useStyles from './styles';
import { useIntl } from 'components/Intl';

interface IProps {
  documentId?: string | number;
  imageLinks?: (string | undefined)[];
  defaultWidth: number;
  hideDownloadButton?: boolean;
}

const ImagesVerticalCarousel = (props: IProps) => {
  const { documentId, imageLinks, defaultWidth, hideDownloadButton } = props;

  const [zoom, setZoom] = useState(INITIAL_ZOOM);
  const [rotate, setRotate] = useState(INITIAL_ROTATE);

  const styles = useStyles();
  const intl = useIntl();

  useEffect(() => {
    setZoom(INITIAL_ZOOM);
    setRotate(INITIAL_ROTATE);
  }, [documentId, imageLinks]);

  const handleIncrementZoom = () => {
    if (zoom < MAX_ZOOM) {
      setZoom((value) => value + STEP_ZOOM);
    }
  };

  const handleDecrementZoom = () => {
    if (zoom > MIN_ZOOM) {
      setZoom((value) => value - STEP_ZOOM);
    }
  };

  const handleRotate = () => {
    setRotate((prevRotate) => (prevRotate === MAX_ROTATE ? STEP_ROTATE : prevRotate + STEP_ROTATE));
  };

  const handleDownloadFiles = () => {
    if (documentId) {
      window.open(buildURL(`${BACKEND_URL}/files/documents/${documentId}`));
    }
  };

  if (imageLinks === undefined || imageLinks?.length === 0) {
    return <NoImagePlaceholder />;
  }

  return (
    <Box height="100%" width={defaultWidth}>
      <Grid container sx={styles.imageContainer}>
        {imageLinks?.map((imageLink) => (
          <ImageItem key={imageLink} src={imageLink} zoom={zoom} rotate={rotate} />
        ))}
      </Grid>

      <Grid item container gap={2} sx={styles.buttonsContainer}>
        <Grid item>
          <IconButton
            onClick={handleIncrementZoom}
            aria-label={intl.formatMessage({ id: 'aria.label.zoom_in' })}
            sx={styles.buttons}
          >
            <SvgIcon htmlColor={COLOURS.WHITE}>
              <Plus />
            </SvgIcon>
          </IconButton>
        </Grid>

        <Grid item>
          <IconButton
            onClick={handleDecrementZoom}
            aria-label={intl.formatMessage({ id: 'aria.label.zoom_out' })}
            sx={styles.buttons}
          >
            <SvgIcon htmlColor={COLOURS.WHITE}>
              <Minus />
            </SvgIcon>
          </IconButton>
        </Grid>

        <Grid item>
          <IconButton
            onClick={handleRotate}
            aria-label={intl.formatMessage({ id: 'aria.label.rotate_image_clockwise' })}
            sx={styles.buttons}
          >
            <SvgIcon htmlColor={COLOURS.WHITE}>
              <ArrowsClockwise />
            </SvgIcon>
          </IconButton>
        </Grid>

        {!hideDownloadButton && (
          <Grid item>
            <IconButton
              onClick={handleDownloadFiles}
              aria-label={intl.formatMessage({ id: 'aria.label.download_image' })}
              sx={styles.buttons}
            >
              <SvgIcon htmlColor={COLOURS.WHITE}>
                <DownloadSimple />
              </SvgIcon>
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ImagesVerticalCarousel;
