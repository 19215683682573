import Button from '@core-ui/button';
import Tooltip from '@mui/material/Tooltip';
import { Files } from '@phosphor-icons/react';
import React from 'react';
import { FormattedMessage, IntlMessageKeys, useIntl } from 'components/Intl';
import useStyles from './styles';

interface IProps {
  onClick: () => void;
  tooltipLabel?: IntlMessageKeys;
  icon?: JSX.Element;
  ariaLabel?: IntlMessageKeys;
  disabled?: boolean;
  dataTestId?: string;
}

// TODO: мб уже лишний компонент, обсудить с дизайнером нужны ли нам тултипы на кнопках, если нет, то выпилить этот компонент
const IconButtonWithTooltip = (props: IProps) => {
  const {
    onClick,
    tooltipLabel,
    icon = <Files />,
    ariaLabel,
    disabled,
    dataTestId = 'icon-button-with-tooltip',
  } = props;

  const styles = useStyles();
  const intl = useIntl();

  return (
    <Tooltip title={<FormattedMessage id={tooltipLabel} />} placement="top" arrow>
      <Button
        onClick={onClick}
        variant="outlined"
        sx={styles.defaultStyles}
        icon={icon}
        disabled={disabled}
        aria-label={intl.formatMessage({ id: ariaLabel ?? 'aria.label.open_item' })}
      />
    </Tooltip>
  );
};

export default IconButtonWithTooltip;
